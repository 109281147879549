import axios from 'axios';
import * as Config from "../../utils/config";

export const UPDATE_DATE = 'appointmentDate:updateAppointmentDate';
export const UPDATE_SERVICES = 'appointmentServices:updateAppointmentServices';
export const GET_DETAILS = 'appointmentDetails:getAppointmentDetails';

export function updateAppointmentDate(day) {
    return {
        type: UPDATE_DATE,
        payload: {
            appointmentDate: day
        }
    }
}

export function updateAppointmentServices(serviceData) {
    return {
        type: UPDATE_SERVICES,
        payload: {
            appointmentServices: serviceData
        }
    }
}

export function getAppointmentDetails(details) {
    return {
        type: GET_DETAILS,
        payload: {
            appointmentDetails: details
        }
    }
}

export function apiPostAppointment(merchantID, data) {
    return dispatch => {
        const appointmentData = data;
        axios({
            method: 'post',
            url: `${Config.API_URL}/merchants/${merchantID}/reservations`,
            headers: {
                'Authorization': Config.TOKEN,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: data
        })
            .then((response) => {
                const data = response.data
                const location = [
                    { 'lat': data.currentLatitude },
                    { 'lng': data.currentLongitude },
                ]
                
                let params = new URLSearchParams(window.location.search);
                const isEmpty = Object.keys(params).length !== 0;
                if (!isEmpty) {
                    let utmData = {
                        merchant_id: merchantID,
                        utm_id: params.get('utm_id'),
                        utm_source: params.get('utm_source'),
                        utm_medium: params.get('utm_medium'),
                        utm_content: params.get('utm_content'),
                        
                        visitor_name: data.customer_name,
                        visitor_mobile: data.customer_mobile_number,
                        visitor_email: data.customer_email,

                        visitor_location: JSON.stringify(location),
                        converted: 1
                    }
        
                    axios({
                        method: 'post',
                        url: `${Config.API_URL}/merchants/${merchantID}/utm/tracking`,
                        headers: {
                            'Authorization': Config.TOKEN,
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        data: utmData
                    })
                }

                if (appointmentData.customer_membership) {
                    const costumer = (typeof data.merchant_customer !== 'undefined')? 
                        data.merchant_customer.is_gift_rewards_member: 
                        data.internalMessage.merchant_customer.is_gift_rewards_member;

                    if (costumer != 1) {
                        const customerId = {
                            "enroll_immediate": 1,
                            "customer_ids": [
                                data.internalMessage.customer_id
                            ]
                        }

                        axios({
                            method: 'post',
                            url: `${Config.API_URL}/merchants/${merchantID}/gift-rewards/members`,
                            headers: {
                                'Authorization': Config.TOKEN,
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            data: customerId
                        })
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error)
                            dispatch(getAppointmentDetails(error));
                        })
                    }
                }

                dispatch(getAppointmentDetails(data));
            })
            .catch((error) => {
                console.log(error);

                dispatch(getAppointmentDetails(error));
            })
    }
}
