import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

const HolidayPage = (props) => {
  const [number, setNumber] = useState('')

  useEffect(() => {
    if (props.merchant) {
      setNumber(props.merchant.phone)
    }
  }, [props.merchant])

  return (
    <div className={'appointment__final text--center'}>
        <h2 style={{ fontWeight: '400' }}>
          Thank you for visiting! </h2>

        <br/>

        <h4 style={{ fontWeight: '300' }}>
          Unfortunately, We are not available for online booking due to holiday today.<br/> <br/>
        </h4>

        <h5 style={{ fontWeight: '200' }}>
          Please call us at {number} if you need help with your appointment
        </h5>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      merchant: state.merchant,
  }
};

export default connect(mapStateToProps)(HolidayPage);