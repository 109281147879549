import { UPDATE_INFO } from '../actions/customer-actions';

function customerReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_INFO:
      return {
        name: payload.customerInformation.name,
        mobile: payload.customerInformation.mobile, // for booking
        email: payload.customerInformation.email,
        mobileNumber: payload.customerInformation.mobile_number, // for appointment viewing
        birthDate: payload.customerInformation.birthDate,
        membership: payload.customerInformation.membership,
        vaccinated: payload.customerInformation.vaccinated,
      };
    default:
      return state;
  }
}

export default customerReducer;
