import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

function Loader(props) {
    return(
        <div className={props.loading ? 'loader flex flex--ai-center flex--jc-center flex--dir-column' : 
            'loader loader--loaded flex flex--ai-center flex--jc-center flex--dir-column'}>
            {/* <HashLoader size={100} color={'#71B2E2'}/> */}
            <HashLoader size={100} color={'#f70077'}/>

            <h5 className={'loader__text'}>loading</h5>
        </div>
    )
}

export default Loader;