import React from 'react';
import error404 from '../../assets/images/error404.png';

function ErrorPage () {
    let header = document.querySelector('.header');

    if (header) {
        header.classList.add('hidden');
    }

    return(
        <div className={'appointment__error text--center'}>
             <img src={error404} className="appointment__error__image" alt="error 404" />
        </div>
    );
}

export default ErrorPage;