import { GET_DETAILS } from '../actions/appointment-actions';

function appointmentReducer (state = [], {type, payload}) {
  switch (type) {
    case GET_DETAILS:
      return payload.appointmentDetails;
    default:
      return state;
  }
}

export default appointmentReducer;
