import React from 'react';
import * as Config from '../../utils/config';

import {ReactComponent as Pin} from '../../assets/images/pin.svg';
import {ReactComponent as Phone} from '../../assets/images/phone.svg';
import {ReactComponent as Mail} from '../../assets/images/mail.svg';

function Header(props) {
    let logo = "";
    let address = "";
    let fullAddress = "";
    let email = "";

    if (props.merchant.image_path) {
        logo = <img src={Config.STORAGE_URL + props.merchant.image_path + props.merchant.id + '.jpg'}
                    alt={props.merchant.business_name} className={'header__business__logo__image'}/>;
    } else {
        let name = props.merchant.business_name;

        if (name) {
            logo = <h3 className={'header__business__logo__text'}>{name.split(" ").map((n) => n[0])}</h3>;
        }
    }

    if (props.merchant) {
        address = `${props.merchant.address_1} ${props.merchant.address_2 ? props.merchant.address_2 : ''} 
            ${props.merchant.city} ${props.merchant.state ? props.merchant.state.title : ''}`;

        fullAddress = props.merchant.address_1;
        fullAddress += props.merchant.address_2 ?  ` ${props.merchant.address_2}` : "";
        fullAddress += `, ${props.merchant.city}`;
        fullAddress += props.merchant.state ? `, ${props.merchant.state.abbreviation}`: "";
        fullAddress += ` ${props.merchant.zip_code}`;
    }

    if (props.merchant) {
        if (props.merchant.merchant_business) {
            email =
                parseInt(props.merchant.merchant_business.show_email_on_customer) === 1 ?
                    <p className={'header__business__contact--email'}><Mail
                        className={'header__business__icon'}/> <a href={`mailto:${props.merchant.email}`}
                                                                  target={"_blank"}>{props.merchant.email}</a></p>
                    : ""
        }
    }


    return (
        <header className={'header'}>
            <div className={'header__bg'}></div>

            <div className={'header__business text--center'}>
                <div className={'header__business__logo'}>
                    {logo}
                </div>

                <h1 className={'header__business__name'}><div dangerouslySetInnerHTML={{__html: props.merchant.business_name}}></div></h1>

                <p className={'header__business__address'}><Pin className={'header__business__icon'}/> <a
                    href={`http://maps.google.com/?q=${fullAddress}`} target={"_blank"}>{fullAddress}</a></p>

                <div className={'header__business__contact flex flex--ai-center flex--jc-center'}>
                    <p className={'header__business__contact--phone'}><a href={`tel: ${props.merchant.phone}`}
                                                                         target={"_blank"}><Phone
                        className={'header__business__icon'}/> {props.merchant.phone}</a></p>
                    {email}
                </div>
            </div>
        </header>
    )
}

export default Header;