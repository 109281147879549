import React from 'react';
import AppointmentForm from './form/Form';
import AppointmentSummary from './summary/Summary';
import AppointmentFinal from './final/Final';
import ErrorPage from '../pages/error-page';
import ServiceUnavailablePage from '../pages/service-unavailable';
import HolidayPage from '../pages/holiday-entries';

function Appointment (props) {
    let currentPage = props.currentPage;
    let view = "";

    if (currentPage === "summary") {
        view = <AppointmentSummary />
    } else if (currentPage === "final") {
        view = <AppointmentFinal />
    } else {
        view = <AppointmentForm />
    }

    if (currentPage === "error") {
        view = <ErrorPage />
    }

    if (currentPage === "unavailable") {
        view = <ServiceUnavailablePage />
    }

    if (currentPage === "holiday") {
        view = <HolidayPage />
    }
    
    return(
        <React.Fragment>
            {view}
        </React.Fragment>
    );
}

export default Appointment;