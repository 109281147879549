import { UPDATE_SERVICES } from '../actions/appointment-actions';

function appointmentServicesReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_SERVICES:
      return [
        ...state
      ]
    default:
      return state;
  }
}

export default appointmentServicesReducer;
