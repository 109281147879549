import { UPDATE_DATE } from '../actions/appointment-actions';

function appointmentServicesReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_DATE:
      return payload.appointmentDate;
    default:
      return state;
  }
}

export default appointmentServicesReducer;
