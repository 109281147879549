import React from 'react'
import * as Config from '../../utils/config';

const MerchantCard = ({ merchant = {} }) => {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  
  let address = ''
  let operations = ''
  let logo = ''

  if (merchant) {
    address = `${merchant.address_1} ${merchant.address_2 ? merchant.address_2 : ''} ${merchant.city} ${merchant.state ? merchant.state.title : ''}`;

    if (merchant.image_path) {
      logo = <img src={Config.STORAGE_URL + merchant.image_path + merchant.id + '.jpg'}
                  alt={merchant.business_name} className={'header__business__logo__image'}/>;
    } else {
        let name = merchant.business_name;

        if (name) {
            logo = <h3 className={'header__business__logo__text'}>{name.split(" ").map((n) => n[0])}</h3>;
        }
    }

    operations = days.map(day => {
      const merchantBusiness = merchant.merchant_business
      let hours = ''

      if (merchantBusiness) {
        if (merchantBusiness[`regular_${day}`] === 1) {
          hours = merchantBusiness[`regular_${day}_from`] + " - " + merchantBusiness[`regular_${day}_to`]
        } else {
          hours = 'CLOSED'
        }
      }

      return <div className="info__flex" key={day}>
        <div className="info__flex__label text-uppercase">{day}</div>
        <div className="info__flex__value text-uppercase">{hours}</div>
      </div>
    })
  }

  return (
    <div className="card card__merchant">
      <div className="header">
        <div className="business-thumb">
          {logo}
        </div>
        <div className="info business-name">
          <div className="info__label">business name</div>
          <div className="info__value lead">{merchant?.business_name}</div>
        </div>
      </div>

      <div className="body">
        <div className="info">
          <div className="info__label">phone</div>
          <div className="info__value">
            <a href={`tel: ${merchant?.phone}`} target={"_blank"} rel="noopener noreferrer">
              {merchant?.phone}
            </a>
          </div>
        </div>

        <div className="info">
          <div className="info__label">email address</div>
          <div className="info__value">
            <a href={`mailto:${merchant.email}`} target={"_blank"} rel="noopener noreferrer">
              {merchant?.email}
            </a>
          </div>
        </div>

        <div className="info">
          <div className="info__label">location</div>
          <div className="info__value">{address}</div>
        </div>

        <div className="info operation-hours">
          <div className="info__label">operation hours</div>

          {operations}
        </div>

        <div className="text-center">
          <a href={`http://maps.google.com/?q=${address}`} target={"_blank"} rel="noopener noreferrer" className="btn directions">
            Check Location on Google Maps
          </a>
        </div>
      </div>
    </div>
  )
}

export default MerchantCard
