import React, { useState, useEffect } from 'react'
import moment from 'moment'

const DetailCard = ({ appointment, main = false }) => {  
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))
  })

  return (
    <div className={`card card__appointment ${main ? 'card__appointment--main' : ''}`}>
      <div className="header">
        <div className="info">
          <div className="info__label">appointment date</div>
          <div className="info__value lead">{width < 768 ? moment(appointment.reservation_date).format('MMM DD, YYYY') : moment(appointment.reservation_date).format('dddd, MMMM DD, YYYY')}</div>
        </div>

        <div className="info">
          <div className="info__label">reference number</div>
          <div className="info__value lead">#{appointment.reference_number}</div>
        </div>
      </div>

      <div className="body">
        {appointment.details.map(detail => {
          return <div className="info__flex service" key={detail.id}>
            <div className="service__info">
              <div className="info__label">service and specialist</div>
              <div className="info__value">
                {detail.service.title} 
                {' '}<span className="highlight">by</span>{' '}
                {detail.specialist_id === 0 ? 'Any Specialist' : detail.service_specialist.first_name + ' ' + detail.service_specialist.last_name}
              </div>
            </div>

            <div className="service__time">
              <div className="info__label">service time</div>
              <div className="info__value">{detail.time_12_hours} - {detail.time_end_12_hours}</div>
            </div>
          </div>
        })}

      </div>
    </div>
  )
}

export default DetailCard
