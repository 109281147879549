import React from 'react';

function Footer(props) {
    let footer = "";

    if (props.merchant) {
        if (props.merchant.show_platform_info) {
            footer = <div className={'text--center'}>
                <h6 className={'footer__text'}>Powered by GO3Reservation</h6>
            </div>;
        }
    }

    return(
        <footer className={'footer'}>
            {footer}
        </footer>
    )
}

export default Footer;