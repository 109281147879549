import { GET_CUSTOMER_APPOINTMENTS } from '../actions/customer-appointment-actions';

function customerAppointmentsReducer (state = [], {type, payload}) {
  switch (type) {
    case GET_CUSTOMER_APPOINTMENTS:      
      return payload.customerAppointments;
    default:
      return state;
  }
}

export default customerAppointmentsReducer;
