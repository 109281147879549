let environment = "prod";

let token = "RESV562915523596768fd7e791046a4c1c851fa7a8a70dd793";
let apiURL = "http://api.go3reservation.com/api";
let storageURL = "http://dev.go3reservation.com/storage";

if(environment === "prod") {
	token = "RESV562915523596768fd7e791046a4c1c851fa7a8a70dd793";
	apiURL = "https://bridge.go3reservation.com/api";
	storageURL = "https://admin.go3reservation.com/storage";	
}

if(environment === "uat") {
	token = "RESV562915523596768fd7e791046a4c1c851fa7a8a70dd793";
	apiURL = "https://uat-bridge.go3reservation.com/api";
	storageURL = "https://uat-ultima.go3reservation.com/storage";	
}

export const
  TOKEN = token,
  API_URL = apiURL,
  STORAGE_URL = storageURL;
