import React, {useState, useEffect } from 'react'
import _ from 'lodash'
import DetailCard from './DetailCard'

const Appointments = ({ customerAppointments }) => {
  const [upcomingAppointments, setUpcoming] = useState([])
  const [pastAppointments, setPast] = useState([])
  const [reservation, setReservation] = useState(null)
  
  const [view, setView] = useState('upcoming')
  const [list, setList] = useState([])

  useEffect(() => {
    if (!_.isEmpty(customerAppointments)) {
      let upcoming = customerAppointments.upcomingAppointments
      
      setReservation(customerAppointments.reservation)
      setList(upcoming)
      setUpcoming(upcoming)
      setPast(customerAppointments.pastAppointments)
    }
  }, [customerAppointments])

  useEffect(() => {
    if (view === 'upcoming') {
      setList(upcomingAppointments)
    } else if (view === 'past') {
      setList(pastAppointments)
    }
  }, [view, upcomingAppointments, pastAppointments])

  return (
    <React.Fragment>
      <div className="header">
        <div className="title">Your appointment(s)</div>
        <div className="actions"></div>
      </div>

      {/* Show main reservation */}
      {reservation && <DetailCard appointment={reservation} main={true} /> }

      <div className="header">
        <div className="title"></div>
        <div className="actions">
          <button className={`btn ${view === 'upcoming' ? 'active' : ''}`} onClick={() => setView('upcoming')}>upcoming</button>
          <button className={`btn ${view === 'past' ? 'active' : ''}`} onClick={() => setView('past')}>past</button>
        </div>
      </div>

      {list.length > 0 
        ? <React.Fragment>
          {list.map(appointment => {
            return <DetailCard appointment={appointment} key={appointment.id} />
          })}
        </React.Fragment>
        : <React.Fragment>
          <div className="no-data">No {view} appointment(s)</div>
        </React.Fragment>}      
    </React.Fragment>
  )
}

export default Appointments
