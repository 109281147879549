import React from 'react'
import { connect } from 'react-redux';
import moment from 'moment'
import { apiRequestCustomerAppointments } from '../actions/customer-appointment-actions';

import _ from 'lodash'

import ErrorPage from '../pages/error-page';
import Loader from '../layouts/loader-layout';

import CustomerCard from './CustomerCard';
import MerchantCard from './MerchantCard';
import Appointments from './Appointments';

class AppointmentDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      page: '',
      customerInformation: {},
      merchant: {},
      customerAppointments: {}
    }
  }

  componentDidMount() {
    const refCode = this.props.match.params.refCode
    const refNumber = this.props.match.params.refNumber
    const currentDate = moment().format('YYYY-MM-DD')

    this.props.onApiRequestCustomerAppointments(refCode, refNumber, currentDate)
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerAppointments !== prevProps.customerAppointments) {
      setTimeout(() => {
        this.setState({
          loading: false
        });
      }, 1000);

      if (this.props.customerAppointments === 'error') {
        this.setState({
          page: 'error'
        })
      }

      if (!_.isEmpty(this.props.customerAppointments)) {        
        this.setState({
          merchant: this.props.merchant,
          customerInformation: this.props.customerInformation,
          customerAppointments: this.props.customerAppointments
        })
      }
    } 

    if (this.props.merchant !== prevProps.merchant) {
      if (this.props.merchant === 'error') {
        this.setState({
          page: 'error'
        })
      }

      this.setState({
        merchant: this.props.merchant
      })
    } 

    if (this.props.customerInformation !== prevProps.customerInformation) {
      this.setState({
        customerInformation: this.props.customerInformation
      })
    } 
  }

  render() {
    const errorPage =
      <React.Fragment>        
        <ErrorPage />
      </React.Fragment>

    const appPage =
      <React.Fragment>
        <div className="appointmentDetailsMain">          
          <div className="info-panel">
            <div className="greeting-header ">
              <div className="greeting-title">
                Thank you for booking with us!
              </div>

              <div className="greeting-sub">
                Here are your appointment details
              </div>
            </div>

            <CustomerCard customer={this.state.customerInformation} />
            <MerchantCard merchant={this.state.merchant} />
          </div>

          <div className="service-panel">
            <Appointments customerAppointments={this.state.customerAppointments} />
          </div>
        </div>
      </React.Fragment>

    return (
      <React.Fragment>
        {this.state.page === "error"
          ? errorPage
          : appPage}

        <Loader loading={this.state.loading} />
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    merchant: state.merchant,
    customerInformation: state.customerInformation,
    customerAppointments: state.customerAppointments,
  }
};


const mapActionsToProps = {
  onApiRequestCustomerAppointments: apiRequestCustomerAppointments
};

export default connect(mapStateToProps, mapActionsToProps)(AppointmentDetails)