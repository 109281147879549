import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/app.min.css';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';

import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';

import { BrowserRouter } from "react-router-dom";

import merchantReducer from './components/reducers/merchant-reducer';
import pageReducer from './components/reducers/page-reducer';
import appointmentReducer from './components/reducers/appointment-reducer';
import appointmentDateReducer from './components/reducers/appointment-date-reducer';
import appointmentServicesReducer from './components/reducers/appointment-services-reducer';
import customerReducer from './components/reducers/customer-reducer';
import customerAppointmentsReducer from './components/reducers/customer-appointments-reducer';


/**
 * Redux Combine All Reducers
 **/
const allReducers = combineReducers({
    merchant: merchantReducer,
    page: pageReducer,
    appointmentDetails: appointmentReducer,
    appointmentDate: appointmentDateReducer,
    appointmentServices: appointmentServicesReducer,
    customerInformation: customerReducer,
    customerAppointments: customerAppointmentsReducer
});

/**
* Redux Combine All Enhancers (For middleware, etc..)
**/
const allStoreEnhancers = compose(
    applyMiddleware(thunk)
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const initState = {
    page: 'form',
    appointmentServices: [{
        service: [],
        specialist: [],
        time: []
    }],
    customerInformation: {
        name: null,
        mobile: null,
        email: null,
        birth_date: null
    }
}

/**
* Redux Store
**/
const store = createStore(
    allReducers,
    initState,
    allStoreEnhancers
);

ReactDOM.render(
    <Provider store={store}> 
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
