import axios from 'axios';
import * as Config from "../../utils/config";
import { updateCustomerInformation } from './customer-actions';
import { getMerchantInfo } from './merchant-actions';

export const GET_CUSTOMER_APPOINTMENTS = 'customer:getAppointments';

export function getAppointments(appointmentInfo) {  
    return {
        type: GET_CUSTOMER_APPOINTMENTS,
        payload: {
            customerAppointments: appointmentInfo
        }
    }
}

export function apiRequestCustomerAppointments(referenceCode, referenceNumber, requestDate) {
    return dispatch => {
        axios.get(`${Config.API_URL}/customer-reservations/${referenceCode}/${referenceNumber}`, {
            headers: {
                'Authorization': Config.TOKEN,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            params: {
                get_history: 1,
                appointment_limit: 5,
                current_date: requestDate
            }
        })
            .then((response) => {
                const result = response.data;

                const appointmentInfo = result.internalMessage
                const customerAppointments = {
                    reservation: appointmentInfo.reservation,
                    pastAppointments: appointmentInfo.past_appointments,
                    upcomingAppointments: appointmentInfo.upcoming_appointments
                }

                dispatch(getAppointments(customerAppointments));
                dispatch(getMerchantInfo(appointmentInfo.merchant));
                dispatch(updateCustomerInformation(appointmentInfo.customer_information))
            })
            .catch((error) => {
                console.log(error);
                dispatch(getAppointments('error'));
            })
    }
}
