import React from 'react'

const CustomerCard = ({ customer }) => {
  const name = customer?.name
  const email = customer?.email
  const mobileString = customer?.mobileNumber
  
  let phone = mobileString 
    ? `(${mobileString.substring(0, 3)}) ${mobileString.substring(3, 6)}-${mobileString.substring(6, 10)}` : ''

  return (
    <div className="card card__customer">
      <div className="info">
        <div className="info__label">client name</div>
        <div className="info__value lead">{name}</div>
      </div>

      <div className="info">
        <div className="info__label">phone</div>
        <div className="info__value">{phone}</div>
      </div>

      <div className="info">
        <div className="info__label">email address</div>
        <div className="info__value">{email}</div>
      </div>
    </div>
  )
}

export default CustomerCard
