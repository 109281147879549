import { UPDATE_PAGE } from '../actions/page-actions';

function pageReducer (state = [], {type, payload}) {
  switch (type) {
    case UPDATE_PAGE:
      return payload.page;
    default:
      return state;
  }
}

export default pageReducer;
