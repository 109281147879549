import React from 'react';
import { connect } from 'react-redux';

function AppointmentFinal (props) {
    const handleAnotherClick = (e) => {
        e.preventDefault();
        
        document.location.reload();
    };

    const handleWebsiteRedirect = (e, webURL) => {
        e.preventDefault();

        window.location.href = webURL;
    }

    let businessWebsite = "";

    if (props.merchant) {
        if (props.merchant.merchant_business) {
            let mb = props.merchant.merchant_business;

            if (mb.business_url) {
                businessWebsite = <div className={'appointment__final__button'}>
                    <button className={'button button--outline'} onClick={e => handleWebsiteRedirect(e, mb.business_url)}>go to main website</button>
                </div>;
            }
        }
    }

    return(
        <div className={'appointment__final text--center'}>
            <h1 className={'appointment__final__thank'}>Thank you <br/> for booking your appointment!</h1>

            <div className={'appointment__final__reference'}>
                reference number 
                <span>{props.appointmentDetails.internalMessage.reference_number}</span>
            </div>

            <h4 className={'appointment__final__remarks'}>
                we will contact you for your appointment confirmation.
            </h4>
            
            <div className={'appointment__final__button'}>
                <button className={'button button--bg'} onClick={e => handleAnotherClick(e)}>make another appointment</button>
            </div>

            {businessWebsite}
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        merchant: state.merchant,
        appointmentDetails: state.appointmentDetails,
    }
};

export default connect(mapStateToProps)(AppointmentFinal);